.options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.option {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 1rem;
    color: #0359b0;
    /*font-weight: bold;*/
    border: 1px solid #e3e3e3;
    padding: .5rem 0;
    cursor: pointer;
}
.option > b {
    font-size: 1.3rem;
}
.option:hover, .active-option {
    background-color: #f5f5f5;
}

.fi-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem;
    flex-wrap: wrap;
}

.preaching {
    display: flex;
    gap: .75rem;
    border: 1px solid #e3e3e3;
    /*background-color: #f0feeb;*/
    font-size: 1.3rem;
    padding: .5rem;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.preaching > div {
    display: inline-block;
}
.preaching-idx {
    padding: 0 0.5rem;
    font-size: 1.56rem;
    font-weight: bold;
    border: 1px solid #c6c6c6;
    font-family: 'Times New Roman', serif;
}